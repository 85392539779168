// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 50px;

  @media (max-width: 805px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  font-size: 1.2em;
  font-weight: 800;
  margin: 10px 0px 40px 0px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 0.7em;
  margin: 10px 0px 30px 0px;
`;

const SectionHeading = styled.div`
  font-size: 0.9em;
  font-weight: 800;
`;

const Paragraph = styled.p`
  font-size: 1em;
`;

function Terms() {
  return (
    <Container>
      <Header>Terms and Conditions</Header>
      <Text>
        <Paragraph>
          CindyApp is a mobile and web application, which is the property of and is supported by ZARENA AD, a company
          duly incorporated and existing under the laws of the Republic of Bulgaria, registered with the Bulgarian
          Commercial Register under UIC 120008977, with seat and management address at: Isaka 66A, Markovo 4108,
          Bulgaria (hereinafter referred to as “ZARENA”, “Us”, “we”, the “Company”).
        </Paragraph>
        <Paragraph>
          The present terms and conditions (the “Terms”) regulate the use of all CindyApp’s products and services,
          including CindyApp’s mobile and web application and its content.
        </Paragraph>
        <Paragraph>
          Please read these Terms carefully before downloading or using CindyApp. These Terms represent a binding legal
          agreement between you (and any other entity on whose behalf you accept these terms) (collectively “you” or
          “your”) and ZARENA (each separately a “Party” and collectively the “Parties”) as of the date you download
          CindyApp mobile app or access CindyApp website (https://www.cindyapp.com/) and its subdomains. Your use of
          CindyApp is subject to these Terms and your use of the services rendered through CindyApp’s mobile and web
          applications will remain subject to the existing agreement governing such.
        </Paragraph>
        <Paragraph>
          BY USING CINDYAPP'S MOBILE AND WEB APPLICATIONS (INCLUDING THEIR CONTENT), AND/OR PRODUCTS AND/OR SERVICES,
          PLACING AN ORDER WITH ZARENA THROUGHT CINDYAPP WEB OR MOBILE APPLICATION, YOU AGREE TO THE TERMS AND
          CONDITIONS THAT FOLLOW, TO ANY POLICIES THAT MAY BE ADDED TO THEM, AND TO ANY REVISIONS OR CHANGES THAT THEY
          MAY BE THE SUBJECT OF. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, OR YOU DO NOT WISH TO BE BOUNDED BY
          THEM, DO NOT USE CINDYAPP'S MOBILE AND WEB APPLICATIONS, PRODUCTS OR SERVICES.
        </Paragraph>
      </Text>
      <SectionHeading>1. Eligibility</SectionHeading>
      <Text>
        <Paragraph>
          You must be at least 14 years of age to access or use CindyApp’s mobile and web applications. If you are under
          18 years of age (or the age of legal majority where you live), you may only access or use CindyApp’s mobile
          and web applications under the supervision of a parent or legal guardian who agrees to be bound by these
          Terms. If you are a parent or legal guardian of a user under the age of 18 (or the age of legal majority), you
          agree to be fully responsible for the acts or omissions of such user in connection with CindyApp’s mobile and
          web applications. If you are accessing or using CindyApp’s mobile and web applications on behalf of another
          person or entity, you represent that you are authorized to accept these Terms on that person or entity’s
          behalf and that the person or entity agrees to be responsible to us if you or the other person or entity
          violates these Terms.
        </Paragraph>
        <Paragraph>
          Proof of compliance to these criteria may be requested upon access to all (or some) services and/or products,
          in the form of identification details or contact information.
        </Paragraph>
      </Text>
      <SectionHeading>2. Your Account</SectionHeading>
      <Text>
        Your use of CindyApp’s mobile and web applications requires that you have an account with CindyApp and agree to
        these Terms. To create an account, you must provide a valid phone number and other personal information. You are
        the sole responsible for the confidentiality of your password and account. All activity originated from your
        account and/or password is your responsibility. We strongly suggest that you keep your account information
        strictly confidential and do not share it with anyone. In case of unauthorized use of your account, immediately
        notify ZARENA in writing from the email address you used to register the account.
      </Text>
      <SectionHeading>3. Services</SectionHeading>
      <Text>
        <Paragraph>You can use the following services through CindyApp’s mobile and web applications:</Paragraph>
        <Paragraph>
          3.1 Free version <br />
          CindyApp’s mobile app for booking appointments is absolutely free of charge to each and all end-users.
        </Paragraph>
        <Paragraph>
          3.2 Paid version <br />
          ZARENA offers a paid version of CindyApp’s mobile and web applications used by businesses to manage their
          schedules.
        </Paragraph>
        <Paragraph>3.2.1. Pricing and Payment</Paragraph>
        <ol type="a">
          <li>Monthly Subscription</li>
          <Paragraph>
            By purchasing a monthly subscription (“Monthly Subscription”), you agree to an initial and recurring Monthly
            Subscription fee at the then-current Monthly Subscription rate, and you accept responsibility for all
            recurring charges until you cancel your subscription. You may cancel your Monthly Subscription at any time,
            subject to the terms of our cancellation policy.
          </Paragraph>
          <Paragraph>
            By purchasing a monthly subscription (“Monthly Subscription”), you agree to an initial and recurring Monthly
            Subscription fee at the then-current Monthly Subscription rate, and you accept responsibility for all
            recurring charges until you cancel your subscription. You may cancel your Monthly Subscription at any time,
            subject to the terms of our cancellation policy.
          </Paragraph>
          <li>Annual Subscription</li>
          <Paragraph>
            By purchasing an annual subscription (“Annual Subscription”), you agree to an initial pre-payment for one
            full year of service. After one year and annually thereafter, you will be billed a recurring Annual
            Subscription renewal fee at the then-current Annual Subscription rate. We will notify you before the renewal
            fee is billed. You may cancel your Annual Subscription any time before the next billing cycle, subject to
            the terms of our cancellation policy.
          </Paragraph>
          <Paragraph>
            AUTOMATIC ANNUAL RENEWAL TERMS: We bill your credit card for the Annual Subscription renewal fee in the
            first month of your next Annual Subscription. For example, let's say you purchased an Annual Subscription
            beginning in May 2018 and ending in April 2019. We will bill you for the second year (May 2019 to April
            2020) in May 2019, unless you cancel your Annual Subscription before May 2019. You accept responsibility for
            all recurring charges prior to cancellation. Once you subscribe, ZARENA will automatically process your
            Annual Subscription fee at the then-current Annual Subscription rate. We explain how to cancel your Annual
            Subscription below at the Section "Cancellation and Refund Policy".
          </Paragraph>
        </ol>
        <Paragraph>3.2.2. Delivery</Paragraph>
        <Paragraph>
          By completing and submitting an electronic order form, you are making an offer to purchase CindyApp’s mobile
          and web application services which, if accepted by us, will result in a binding contract. Please note you
          would not be given access to the purchased services until we have authorization from your payment card issuer.
          We will not be liable if there is a delay, and we will not accept your offer if payment is not authorized.
        </Paragraph>
        <Paragraph>Certain steps must be followed for a contract to be formed, as described below:</Paragraph>
        <Paragraph>
          After you have placed your order, you will receive an email to acknowledge your order. It will contain a
          confirmation of the services you have ordered as well login instructions.
        </Paragraph>
        <Paragraph>
          We do not have to accept your order, and for example, we will not accept your order if:
          <ul>
            <li>Your payment is not authorized</li>
            <li>There is an error on our website regarding the price or other details of the services</li>
            <li>You have cancelled your order</li>
          </ul>
          We reserve the right to refuse any order.
        </Paragraph>
        <Paragraph>3.2.3. Cancellation and Refund Policy</Paragraph>
        Cancellation Policy for Monthly Subscription Renewals: You may cancel your Monthly Subscription at any time by
        logging into your account, clicking on Payments, and following the cancellation procedures described there. If
        you need help, feel free to email us at: support@cindyapp.com. If for some reason you cannot cancel by logging
        into your account, please email us at the same email address to cancel your waitlist position for the Monthly
        Subscription. If you cancel your Monthly Subscription, the cancellation will take effect for your next monthly
        billing cycle. You will not be eligible for a refund for monthly subscription fees paid prior to the month the
        cancellation takes effect.
        <br />
        <br />
        Cancellation Policy for Annual Subscription Renewals: Annual Subscription fees are non-refundable. You may
        cancel your Annual Subscription renewal at any time after you are billed for the then-current year and before
        you are billed for the next year, by logging into your account, clicking on Payments, and following the
        cancellation procedures or by emailing us at: support@cindyapp.com.
      </Text>
      <SectionHeading>4. Privacy</SectionHeading>
      <Text>
        Please refer to our Privacy Policy (an integral part of the present Terms) for information about how we collect,
        use and disclose information about you.
      </Text>
      <SectionHeading> 5. Acceptable Usage</SectionHeading>
      <Text>
        "Acceptable Usage" of CindyApp's mobile and web applications, their contents, products and services are defined
        in Paragraphs 5.1 through 5.8 below. Activities that go against or are otherwise forbidden by these paragraphs
        are, therefore in violation of these Terms and make you liable to disciplinary action and legal prosecution. For
        more information, please refer to Article 9 below.
        <br />
        <br />
        <Paragraph>5.1. User Content</Paragraph>
        <Paragraph>
          You will not post on nor distribute through CindyApp's mobile and web applications any materials that are of
          defamatory, threatening, obscene, harmful, pornographic or otherwise illegal nature. Also, materials that
          somehow violate or infringe, in any way, on our rights or on the rights of others (including but not limited
          to intellectual property rights, confidentiality rights and privacy rights) are absolutely forbidden, as well
          as activities that may cause distress or inconvenience on Us or others. Moreover, you may not express opinions
          that are vulgar, crude, sexist, racist or otherwise offensive. We encourage Users to treat each other in a
          respectful and polite manner.
        </Paragraph>
        <Paragraph>5.2. Ownership of Content</Paragraph>
        <Paragraph>
          You will not post nor otherwise make available on CindyApp's mobile and web applications any material which
          you do not own, without the express consent of the lawful owner of the material in question.
        </Paragraph>
        <Paragraph> 5.3. Participation in Events</Paragraph>
        <Paragraph>
          You will abide by the rules of any competition, promotion or marketing campaign that you participate on
          CindyApp's mobile and web applications.
        </Paragraph>
        <Paragraph>5.4. Interference</Paragraph>
        <Paragraph>
          You will not engage in any behavior or action that may affect the operability or security of CindyApp’s mobile
          and web applications nor will you cause unreasonable inconvenience nor disruption to our staff.
        </Paragraph>
        <Paragraph>5.5. Identity</Paragraph>
        <Paragraph>
          You will not impersonate any person/entity, nor misrepresent your affiliation with a person/entity.
        </Paragraph>
        <Paragraph>5.6. SPAM</Paragraph>
        <Paragraph>
          You will not promote your “CindyApp” profile by sending SPAM (i.e., emails sent to accounts without the
          previous agreement of the account owner).
        </Paragraph>
        <Paragraph>5.7. Robot Software</Paragraph>
        <Paragraph>
          You will not use robot software (nor any other software that is not “CindyApp”) to create new “CindyApp”
          profiles nor to access nor modify “CindyApp” profiles.
        </Paragraph>
        <Paragraph>5.8. Workarounds</Paragraph>
        <Paragraph>
          You will not use workarounds of any kind, to overcome the limitations of any “CindyApp” service or feature.
        </Paragraph>
      </Text>
      <SectionHeading>6. Licence</SectionHeading>
      <Text>
        ZARENA grants you a revocable, non-exclusive, non-transferable, non-sublicensable, limited license to download,
        install, and use CindyApp mobile and web applications for your personal and internal business purposes strictly
        in accordance with these Terms. Such license is subject to these Terms and does not include any right to: (a)
        sell, resell or commercially use our CindyApp’s mobile and web applications’ services or content; (b) copy,
        reproduce, distribute, publicly perform or publicly display CindyApp’s mobile and web applications’ Content,
        except as expressly permitted by us or our licensors; (c) modify CindyApp’s mobile and web applications’
        content, remove any proprietary rights notices or markings, or otherwise make any derivative uses of our
        services or CindyApp’s mobile and web applications’ content, except as expressly set forth in these Terms; (d)
        use any data mining, robots or similar data gathering or extraction methods; or (e) use our services or
        CindyApp’s mobile and web applications’ content other than as expressly provided in these Terms. Any use of our
        services or CindyApp’s mobile and web applications’ content other than as specifically authorized herein,
        without our prior written permission, is strictly prohibited and will terminate the license granted under these
        Terms. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights
        notices incorporated in or accompanying CindyApp’s mobile and web applications’ content.
      </Text>
      <SectionHeading>7. Intellectual Property</SectionHeading>
      <Text>
        All intellectual property rights (including all copyright, patents, trademarks, service marks, trade names,
        domain names, social media identifiers, designs, whether registered or unregistered) in CindyApp’s mobile and
        web applications and the services rendered through them, materials, information and content on CindyApp’s mobile
        and web applications or accessed as part of CindyApp’s service, any database operated by us, all CindyApp’s
        mobile and web applications’ design, text, graphics, software, photos, video, music, sound, data, all software
        compilations, underlying source code and software (including applets and scripts), and all their selection,
        coordination, arrangement and enhancement shall remain our property (or that of our licensors). You shall not,
        and shall not attempt to, obtain any ownership or title to any such property. All rights are reserved. Moreover,
        you are only allowed to use such material in the manner described in the Terms, and no other. You will not copy,
        reproduce, distribute, commercially exploit nor in any form benefit/profit from such materials or contents, nor
        you will assist/facilitate any third party in actions such as the ones mentioned above.
        <br />
        <br />
        Moreover, if you become aware of any such distribution or commercial exploitation, you agree to notify us
        immediately.
      </Text>
      <SectionHeading>8. Consent to Electronic Communications and Solicitation.</SectionHeading>
      <Text>
        By downloading CindyApp’s mobile app and creating an account in the CindyApp’s web app, you authorize ZARENA to
        send you (including via email and push notifications) information regarding the subscription services and
        CindyApp’s mobile and applications, such as: (a) notices about your use of the subscription services and the
        said applications, including notices of violations of use; (b) updates to the subscription services and the
        applications and new features or products; (c) reminders for upcoming appointments and (d) promotional
        information and materials regarding ZARENA's products and services. You can review Your account notification
        settings and adjust your messaging preferences, including opting-in to additional messages or unsubscribing to
        certain messaging.
      </Text>
      <SectionHeading>9. Violation of Our Terms</SectionHeading>
      <Text>
        Failure to comply with the conditions stated in Article 5 above and/or participation in activities that go
        against or are otherwise forbidden by these Terms make you liable to disciplinary action and legal prosecution.
        The decision to initiate disciplinary action (including but not being limited to interruption of all services)
        falls under the discretion of ZARENA.
        <br />
        <br />
        Please report infractions to the present Terms or to your local laws. To inform us of any such activities, send
        us an email at support@cindyapp.com.
      </Text>
      <SectionHeading>10. Interruption/Termination of Services</SectionHeading>
      <Text>
        We reserve the right to immediately terminate or suspend indefinitely our services to you, if you breach or we
        have reasonable grounds to believe that you are likely to breach these Terms. Also, we will terminate our
        services to you, at our sole discretion, if you engage in a conduct which We determine to be unacceptable as
        described under article 5 above.
      </Text>
      <SectionHeading>11. No Included Maintenance and Support</SectionHeading>
      <Text>
        ZARENA may deploy changes, updates, or enhancements to CindyApp’s mobile and web applications at any time.
        ZARENA may provide maintenance and support for the CindyApp’s mobile and web applications, but has no obligation
        whatsoever to furnish such services to you and may terminate such services at any time without notice. You
        acknowledge that neither Apple (for iOS Mobile App) nor Google (for Android Mobile App) has an obligation to
        furnish any maintenance or support services in connection with CindyApp mobile app.
      </Text>
      <SectionHeading>12. No warranty</SectionHeading>
      <Text>
        YOUR USE OF CINDYAPP’S MOBILE AND WEB APPLICATIONS IS AT YOUR SOLE RISK. CINDYAPP’S MOBILE AND WEB APPLICATIONS
        ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE EXTENT ALLOWED UNDER LAW, ZARENA EXPRESSLY DISCLAIMS
        ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOU FURTHER ACKNOWLEDGE AND AGREE THAT WE ARE NOT
        RESPONSIBLE FOR THE AVAILABILITY AND/OR FUNCTIONALITY OF ANY THIRD PARTY'S WEBSITE AN/OR MATERIAL YOU MAY ACCESS
        THROUGH CINDYAPP MOBILE OR WEB APPLICATION.
        <br />
        <br />
        CindyApp’s mobile and web applications are only available for supported devices and might not work on every
        device. Determining whether your device is a supported or compatible device for use of the applications is
        solely your responsibility, and downloading CindyApp’s mobile application is done at your own risk. ZARENA does
        not represent or warrant that CindyApp’s mobile and web applications and your device are compatible or that
        CindyApp’s mobile or web applications will work on your device.
      </Text>
      <SectionHeading>13. Disclaimer</SectionHeading>
      <Text>
        WE ARE NOT RESPONSIBLE FOR DAMAGES TO USERS OR TO THIRD PARTIES OR TO THEIR HARDWARE THAT OCCUR DIRECTLY,
        INDIRECTLY NOR ACCIDENTALLY AS A RESULT OF NOR IN CONNECTION WITH THE USE OF OUR SERVICES AND/OR PRODUCTS,
        INCLUDING THE DOWNLOAD OF MATERIAL THROUGH OUR SERVICES. WE ARE NOT LIABLE FOR DAMAGE TO USERS OR THIRD PARTIES
        AS A RESULT OF THE INABILITY TO USE OUR SERVICES AND/OR OUR MOBILE APPLICATION AND/OR PRODUCTS, NOR DIRECT,
        INDIRECT, NOR IN CONNECTION WITH THIS EVENT. HENCE, YOUR USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND
        RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT
        RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIALS.
      </Text>
      <SectionHeading>14. Third Parties</SectionHeading>
      <Text>
        We do not endorse and shall not be held responsible nor liable for any content, advertising, product or service
        on or available through third-party websites. Any transactions between you and any third party found on or via
        the CindyApp mobile or web application, including payment for and delivery of products, services and any other
        terms, conditions, warranties or representations associated with such dealings, are made between you and the
        entity in question. Therefore, we are neither responsible nor liable for any loss/damage of any sort, incurred
        in such dealings.
      </Text>
      <SectionHeading>15. Feedback</SectionHeading>
      <Text>
        As part of our commitment to excellence, ZARENA welcomes your comments and suggestions to some or all of the
        conditions and policies stated on the present Terms. In order to contribute with your comment/suggestion,
        contact our Customer Care department, or send an email to: support@cindyapp.com.
      </Text>
      <SectionHeading>16. Changes of the Terms</SectionHeading>
      <Text>
        ZARENA reserves the right to, at any time, modify these Terms, at its own discretion, to take into account
        legal, industry-related, or any other changes in the status quo. The latest updated version of these Terms
        agreement is available at www.cindyapp.com/terms. You will receive a notification for update of the Terms by
        email , at least 10 days prior to the effective date of the new version. Your continued use of CindyApp mobile
        or web application after ZARENA sends you a notice of the changes indicates your consent to the updated terms.
      </Text>
      <SectionHeading>17. Severability</SectionHeading>
      <Text>
        If any court or regulator decides that any provision of these Terms is invalid or otherwise unenforceable, such
        provision shall be severed and deleted from these Terms and the remainder terms and conditions shall continue to
        have full force and effect.
      </Text>
      <SectionHeading>18. Governing Law and Dispute Resolution</SectionHeading>
      <Text>
        If any of the above provisions are declared null and void, this clause will not affect the validity of the other
        clauses.
        <br />
        <br />
        This document is drawn up and will be interpreted in accordance with Bulgarian law.
        <br />
        <br />
        Any disputes arising out of or relating to these Terms or any additional agreements thereto, including disputes
        arising out of or relating to interpretation, invalidity, non-performance or termination, shall be resolved by
        mutual consent. If no agreement is reached, disputes will be resolved by court order, in accordance with the
        provisions of the Bulgarian law.
      </Text>
    </Container>
  );
}

export default Terms;
